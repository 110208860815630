@keyframes drive {
  0%{left: -50%}
  50%{left:50%}
  100%{left:100%}
}

@keyframes wheel {
  0%{transform:rotate(720deg)}
  50%{transform:rotate(0deg)}
  100%{transform: rotate(-360deg);}
}

#body {
  background: linear-gradient(0deg, rgba(246, 249, 249, 0.3), rgba(255, 246, 251, 0.3)), url("C:\Users\LordS\Code\Kacevedo\resume\src\Road.avif") no-repeat scroll center;
  background-size: cover;
}

#body.Dark{
  background: linear-gradient(0deg, rgba(20, 15, 18, 0.3), rgba(49, 38, 44, 0.3)), url("C:\Users\LordS\Code\Kacevedo\resume\src\Space.jpg") no-repeat scroll center;
  background-size: cover;
}

.Dark {
  background-color: transparent;
  /* background-color: #282c34; */
  color: white;
}

.Light {
  background-color: transparent;
  /* background-color: #fffbf4; */
  color: black;
}

a.Light {
  color: black;
}

.Nav {
  width:100%;
  position:fixed;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  scroll-snap-type: x mandatory;
}

.Nav a {
  padding:1rem;
  flex-shrink: 0;
  scroll-snap-align: start;
}

.Nav button {
  border: none;
  background: transparent;
  color: inherit;
  font: inherit;
  padding: 1rem;
}

.ScrollNav {
  width:100%;
  position:fixed;
  top:10%;
  text-align: center;
  margin:0
}

.Header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
}

.LDButton {
  position:fixed;
  top: 0;
  right: 0;
  margin:.5rem;
}

.Title{
  width:80%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.SVGContainer {
  overflow-x:hidden;
}

svg{
  width:50%;
  height:auto;
  position:relative;
  animation: drive 5s ease-in-out 2s infinite;
  left:-50%
}

#Wheel{
  transform-box: fill-box;
  transform-origin: center;
  animation: wheel 5s ease-in-out 2s infinite;
}

.RModal {
  min-height: 100vh;
  width:100%;
  position: fixed;
  top:0;
  left:0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
  z-index: 1000
}

.RModal.Dark, .InnerModal.Dark{
  background-color:rgba(0, 0, 0, 0.7);
}

.RModal.Light, .InnerModal.Light{
  background-color:rgba(255, 255, 255, 0.7);
}

.InnerModal{
  padding: 1rem;
  width: 90%;
  display: flex;
  flex-direction: column;
}

.RTitle{
  text-align: center;
}

.Close{
  align-self: end;
  font-weight: bold;
  font-size: 24px;
  border-radius: 50%;
  border:none;

}